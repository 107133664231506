<script>
import Intersect from "vue-intersect";
export default {
  name: "account-subview",

  components: {
    Intersect,
  },

  props: {
    barTitle: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      intersected: false,
    };
  },
};
</script>

<template>
  <div class="subview">
    <div id="account__bar" :class="{ intersected }">
      <div class="bar__left">
        <button class="button sidebarToggle">
          <i class="fas fa-bars"></i>
        </button>
        <div class="barTitle">
          <span class="barTitle__text">{{ barTitle }}</span>
        </div>
      </div>
      <div class="bar__right"></div>
    </div>
    <Intersect
      @enter="intersected = false"
      @leave="intersected = true"
      :threshold="[0, 1]"
      rootMargin="0px 0px 0px 0px"
    >
      <div class="intersect-account__bar" />
    </Intersect>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
#account__bar.intersected {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
  .barTitle {
    .barTitle__text {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition: transform 0.15s ease;
    }
  }
}

#account__bar {
  // padding: 0 var(--sitio-ui-gap-size);
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .bar__left,
  .bar__right {
    display: flex;
    align-items: center;
    height: 3rem;
  }
  .sidebarToggle {
    border: none;
    margin-left: -0.75em;
    margin-right: 0.75rem;
    background-color: transparent;
    // display: inline-flex;
  }
  .barTitle {
    display: flex;
    align-items: center;
    overflow-y: hidden;
    height: 3rem;
    .barTitle__text {
      cursor: pointer;
      font-weight: bold;
      position: relative;
      opacity: 0;
      visibility: hidden;
      transform: translateY(48px);
      transition: opacity 0.15s ease, transform 0.15s ease,
        visibility 0s linear 0.15s;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    title: String,
    description: String,
    amount: Number,
    onConfirm: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters("session", ["partner", "sponsored"]),
    message() {
      if (this.sponsored) {
        return `<strong>NOTA:</strong> Estas afiliado a una organización (${this.partner.nickname}) que te ha brindado permisos para consumir sus créditos. <b>¿Quizás solo debes solicitar que recarguen el balance de la organización?</b>`;
      }
      return null;
    },
  },

  watch: {
    $route() {
      this.$parent.close();
    },
  },
};
</script>

<template>
  <div id="insufficient-funds" class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Fondos insuficientes</p>
    </header>
    <section class="modal-card-body">
      <b-message type="is-success" v-if="message">
        <div v-html="message"></div>
      </b-message>
      <div class="content">
        <p>
          Puedes recargar tu balance personal
          <router-link :to="{ name: 'account-credits' }"
            >comprando créditos.</router-link
          >
        </p>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button" @click="$parent.close()">Cerrar</button>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
#insufficient-funds {
  // margin: 0 1rem;
  max-width: 30rem;
  .message {
    // font-size: 0.9375rem;
    font-size: 0.875rem;
  }
}
</style>
